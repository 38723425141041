import clsx from 'clsx';
import Link from 'next/link';
import { useMemo, type ComponentType } from 'react';
import type { ProductCardProps } from '../../cards/product/ProductCard';
import { ProductCard } from '../../cards/product/ProductCard';
import { Button } from '../../ui/button/Button';
import { ScrollSnapSlider } from '../scrollSnapSlider/ScrollSnapSlider';

export type RecommendationsManualProps = {
  title: string;
  description?: string;
  descriptionLink?: CTA;
  cta?: CTA;
  products: Array<ProductCardProps>;
  backgroundColor?: boolean;
  viewModeOnlyMobile?: boolean;
  placement?: 'blog';
  isLoading?: boolean;
  productCardComponent?: ComponentType<ProductCardProps>;
};

type CTA = {
  title: string;
  url: string;
  newTab: boolean;
};

export const RecommendationsManual = ({
  title,
  description,
  descriptionLink,
  cta,
  products,
  backgroundColor,
  viewModeOnlyMobile,
  placement,
  isLoading = false,
  productCardComponent: ProductCardComponent = ProductCard,
}: RecommendationsManualProps) => {
  const dynamicVariables = useMemo(() => {
    if (viewModeOnlyMobile) {
      return {
        fontClasses: {
          title: 'text-heading-h2-mobile',
        },
        styleClass: 'productRecommendationManual__mobileViewOnly',
      };
    }

    if (placement === 'blog') {
      return {
        fontClasses: {
          title: 'text-heading-h2-desktop text-heading-h2-mobile',
        },
        styleClass: 'productRecommendationManual__devirseViewModeBlog',
      };
    }

    return {
      fontClasses: {
        title: 'text-heading-h2-desktop text-heading-h2-mobile',
      },
      styleClass: 'productRecommendationManual__devirseViewMode',
    };
  }, [viewModeOnlyMobile, placement]);

  if (!products.length && !isLoading) return null;

  const css = `
  .productRecommendationManual {
    padding-block-start: var(--spacing-xl);
    padding-block-end: var(--spacing-xl);
  }
  .productRecommendationManual__mobileViewOnly {
    padding-block-start: 0;
    padding-block-end: 0;

    --recommendation-product-width: 158px;
    --recommendation-product-height: 341px;
    --recommendation-header-wrapper-margin-bottom: var(--spacing-m);
    --recommendation-button-display: none;
    --recommendation-width: 100%;
    --recommendation-margin-block-start: var(--spacing-xl);
    --recommendation-margin-block-end: var(--spacing-m);
    --recommendation-view-transform: translate(var(--margin-mobile));
    --recommendation-title-inline-spacing: 0;
    --recommendation-marrgin-inline-start: var(--margin-mobile);
  }

  .productRecommendationManual__devirseViewMode,
  .productRecommendationManual__devirseViewModeBlog {
    --recommendation-product-width: 353px;
    --recommendation-product-height: auto;
    --recommendation-header-wrapper-margin-bottom: var(--spacing-l);
    --recommendation-button-display: unset;
    --recommendation-width: 100vw;
    --recommendation-margin-block-start: var(--spacing-5xl);
    --recommendation-margin-block-end: var(--spacing-l);
    --recommendation-view-transform: 2.5px;
    --recommendation-title-inline-spacing: var(--margin-desktop);
    --recommendation-marrgin-inline-start: var(--margin-desktop);
  }

  .productRecommendationManual__carousel {
    --arrow-top-spacing: 112px;
  }

  .productRecommendationManual__carousel.onlyMobile {
    --arrow-top-spacing: 97px;
  }

  .productRecommendationManual__devirseViewModeBlog {
    --recommendation-product-width: 282px;
  }

  .productRecommendationManual__carouselWrapper {
    width: 100vw;
    --itemview-transform: translate(var(--margin-mobile));
    margin-inline-start: calc(var(--margin-mobile) * -1);
  }

  .productRecommendationManual__headerWrapper {
    margin-bottom: var(--recommendation-header-wrapper-margin-bottom);
  }

  .productRecommendationManual__titleWrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    gap: var(--spacing-m);
    justify-content: space-between;
  }

  .productRecommendationManual__cta {
    display: var(--recommendation-button-display);
    flex-shrink: 0;
    text-decoration: none;
  }

  @media (max-width: 1023.98px) {
    .productRecommendationManual__mobileHidden {
      display: none;
    }

    .productRecommendationManual__productWrapper {
      width: 158px;
      height: 341px;
    }
  }

  @media (min-width: 1024px) {
    padding-block-start: var(--spacing-5xl);
    padding-block-end: var(--spacing-5xl);

    .productRecommendationManual__carousel {
      --arrow-top-spacing: 218px;
    }

    .productRecommendationManual__carousel.onlyMobile {
      --arrow-top-spacing: 97px;
    }

    .productRecommendationManual__mobileViewOnly {
      padding-block-start: 0;
      padding-block-end: 0;
    }

    .productRecommendationManual__headerWrapper {
      margin-bottom: var(--recommendation-header-wrapper-margin-bottom);
    }

    .productRecommendationManual__carouselWrapper {
      --itemview-transform: var(--recommendation-view-transform);

      margin-inline-start: calc(
        var(--recommendation-marrgin-inline-start) * -1
      );
      width: calc(
        100% + (var(--recommendation-marrgin-inline-start) * 2) - 13px
      );
    }

    .productRecommendationManual__carouselWrapper.blog {
      /*
        Recommendations section in blog article pages flow out of parent container.
        Parent container is taking 7 columns out of 9.
        All parent container content is inside the 7 columns boundaries,
        except for Recommendations section it fills the whole 9 columns.
        So we add a negative margin of 100% / -9 which is one column width.
      */
      margin-inline-end: calc(100% / -9);
      margin-inline-start: calc(100% / -9);
      width: auto;
    }

    .productRecommendationManual__productWrapper {
      width: var(--recommendation-product-width);
    }

    .productRecommendationManual__titleWrapper {
      padding-inline-end: var(--recommendation-title-inline-spacing);
      padding-inline-start: var(--recommendation-title-inline-spacing);
    }

    .productRecommendationManual__titleWrapper.blog {
      /*
        Recommendations section in blog article pages flow out of parent container.
        Parent container is taking 7 columns out of 9.
        All parent container content is inside the 7 columns boundaries,
        except for Recommendations section it fills the whole 9 columns.
        So we add a negative margin of 100% / -9 which is one column width.
      */
      margin-inline-end: calc(100% / -9);
      margin-inline-start: calc(100% / -9);
      padding-inline-end: 0;
      padding-inline-start: 0;
    }

    .productRecommendationManual__description {
      display: inline;
    }
  }
  `;

  return (
    <>
      <style type="text/css">{css}</style>
      <section
        className={clsx(
          'productRecommendationManual',
          dynamicVariables.styleClass,
          backgroundColor && 'productRecommendationManual--background',
          'productRecommendationManual__carousel',
          viewModeOnlyMobile && 'onlyMobile'
        )}
      >
        <div className="productRecommendationManual__headerWrapper">
          <div
            className={clsx(
              'productRecommendationManual__titleWrapper',
              placement === 'blog' && 'blog'
            )}
          >
            <div className="productRecommendationManual__titleTextWrapper">
              {title && (
                <h2
                  className={clsx(
                    'productRecommendationManual__title',
                    dynamicVariables.fontClasses.title
                  )}
                >
                  {title}
                </h2>
              )}

              <div
                className={'productRecommendationManual__descriptionWrapper'}
              >
                {description && (
                  <p
                    className={clsx(
                      'productRecommendationManual__description',
                      'text-body-1-desktop',
                      'text-body-2-mobile'
                    )}
                  >
                    {description}
                  </p>
                )}
                {descriptionLink?.title && (
                  <>
                    {' '}
                    <Link
                      href={descriptionLink.url}
                      className={clsx(
                        'productRecommendationManual__descriptionLink',
                        'text-body-1-desktop',
                        'text-body-2-mobile'
                      )}
                      target={descriptionLink.newTab ? '_blank' : undefined}
                    >
                      {descriptionLink.title}
                    </Link>
                  </>
                )}
              </div>
            </div>

            {cta && (
              <Button
                type={'button'}
                className={clsx(
                  'productRecommendationManual__cta',
                  'productRecommendationManual__mobileHidden'
                )}
                modifiers={['white', 'outlined']}
                href={cta.url}
                target={cta.newTab ? '_blank' : undefined}
              >
                <span>{cta.title}</span>
              </Button>
            )}
          </div>
        </div>

        <div
          className={clsx(
            'productRecommendationManual__carouselWrapper',
            placement === 'blog' && 'blog'
          )}
        >
          <ScrollSnapSlider
            id="nailArtCollectionCarousel"
            height={null}
            spacing="5px"
          >
            {!isLoading &&
              products.map((product, index) => {
                return (
                  <div
                    className="productRecommendationManual__productWrapper"
                    key={index}
                  >
                    <article
                      style={{ height: '100%' }}
                      data-test-id="productCard"
                    >
                      <ProductCardComponent
                        {...product}
                        isLoading={isLoading}
                        viewModeOnlyMobile={viewModeOnlyMobile}
                        location="recommendations"
                      />
                    </article>
                  </div>
                );
              })}

            {isLoading &&
              [...Array(4).keys()].map((key) => {
                return (
                  <div
                    className="productRecommendationManual__productWrapper"
                    key={key}
                  >
                    <article
                      style={{ height: '100%' }}
                      data-test-id="productCard"
                    >
                      <ProductCardComponent
                        viewModeOnlyMobile={viewModeOnlyMobile}
                        location="recommendations"
                        isLoading={true}
                      />
                    </article>
                  </div>
                );
              })}
          </ScrollSnapSlider>
        </div>
      </section>
    </>
  );
};
