import { useQueries } from '@tanstack/react-query';
import type { ProductCardProps } from '@we-make-websites/ui-lib';
import { useContext } from 'use-context-selector';
import type { StoreLocale } from '@/root/constants';
import { CustomerContext } from 'components/customerContext/CustomerContext';
import { localeToCountryLanguage } from 'components/route/utils/localeToCountryLanguage';
import { useGetProductQuery } from 'lib/shopify-storefront/__generated__/GetProduct';
import { storefrontQueryDataSource } from 'lib/shopify-storefront/dataSources';
import { formatProductForProductCard } from 'utils/format/shopify/formatProduct';

/**
 * A hook for querying product's to use with Product Cards.
 * @param products - The products to query.
 * @param locale - The store locale.
 * @returns {Object}
 */
export const useProductCardQuery = (
  products: Array<{ handle: string }>,
  locale: StoreLocale
): {
  products: ProductCardProps[];
  isLoading: boolean;
} => {
  const localeCodes = localeToCountryLanguage(locale);
  const { proCustomer } = useContext(CustomerContext);

  const response = useQueries({
    queries: products.map((product) => {
      const variables = {
        handle: proCustomer ? `${product.handle}-pro` : product.handle,
        includeReferenceMetafieldDetails: false,
        ...localeCodes,
      };

      return {
        queryKey: useGetProductQuery.getKey(variables),
        queryFn: useGetProductQuery.fetcher(
          storefrontQueryDataSource(locale),
          variables
        ),
        select: formatProductForProductCard,
      };
    }),
  });

  const productsRaw: ProductCardProps[] = response
    .filter((response) => Boolean(response?.data))
    .map((result) => ({ product: result.data, isLoading: result.isLoading }));

  return {
    products: productsRaw,
    isLoading: response.some((result) => result.isLoading),
  };
};
