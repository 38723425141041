/**
 * Format product card.
 * - Formats product data from a Shopify product query, and shapes it to be used
 *   in a ProductCard component.
 */

import type { ProductCardProduct } from '@we-make-websites/ui-lib';
import type { StoreMediaImage } from 'components/productContext/types/storeMedia';
import type { GetProductQuery } from 'lib/shopify-storefront/__generated__/GetProduct';
import { getCleanProductHandle } from 'utils/helpers';
import { getProductImages } from './formatProductMedia';

export const formatProductForProductCard = (
  originalData: GetProductQuery
): ProductCardProduct | undefined => {
  if (!originalData.product) {
    return;
  }

  const productHandle = getCleanProductHandle(
    originalData.product.handle ?? ''
  );
  const url = productHandle ? `/products/${productHandle}` : '';

  const product: ProductCardProduct = {
    id: originalData.product.id ?? '',
    title: originalData.product.title ?? '',
    handle: productHandle,
    price: {
      amount: Number(originalData.product.priceRange.minVariantPrice.amount),
      currencyCode:
        originalData.product.priceRange.minVariantPrice.currencyCode,
    },
    comparePrice: {
      amount: Number(
        originalData.product.compareAtPriceRange.maxVariantPrice.amount
      ),
      currencyCode:
        originalData.product.compareAtPriceRange.maxVariantPrice.currencyCode,
    },
    availableForSale: !!originalData.product.availableForSale,
    systemName: originalData.product.productType || '',
    url,
    tags: originalData.product.tags,
    ultaProductUrl: originalData.product.meta_ulta_product_url?.value,
    customBuyNowLabel: originalData.product.meta_custom_buy_now_label?.value,
    customBuyNowUrl: originalData.product.meta_custom_buy_now_url?.value,
    asin: originalData.product.meta_asin?.value,
    barcode: originalData.product.variants?.edges[0]?.node.barcode || '',
    secondaryImage: originalData.product.meta_hover_image?.value,
    hasAwards: !!originalData.product.meta_awards_icons?.value,
  };

  /**
   * Image Logic
   */
  const mediaImages: StoreMediaImage[] = getProductImages(originalData.product);

  if (mediaImages.length) {
    product.image = mediaImages[0].image.url || '';
    product.images = mediaImages.map((mediaImage) => {
      return {
        src: mediaImage.image.url,
        alt: mediaImage.image.altText,
        height: mediaImage.image.height,
        width: mediaImage.image.width,
      };
    });
  }

  /**
   * Pro-only logic.
   * - Check the product have true as value of 'Pro Only' metafield.
   * - Check if all variants have the `pro_only` metafield.
   */
  const hasProOnlyMetafield =
    (originalData.product.meta_pro_only?.value || '').toLowerCase() === 'true';

  product.proOnly = hasProOnlyMetafield;

  if (originalData.product.variants) {
    const variants = originalData.product.variants.edges;

    /** Handle pro only logic */
    const allVariantsProOnly = variants.every((variant) => {
      const proOnlyVariantMeta = variant.node.meta_pro_only?.value;
      return proOnlyVariantMeta === 'true';
    });

    product.proOnly = allVariantsProOnly || hasProOnlyMetafield;

    /** Get id of first available variant */
    const firstAvailableVariant = variants.find((variant) => {
      return variant.node.availableForSale;
    });

    if (firstAvailableVariant) {
      product.firstAvailableVariantId = firstAvailableVariant.node.id;
    }
  }

  return product;
};
