/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable import/order */
'use client';

import clsx from 'clsx';
import type { ReactNode, CSSProperties } from 'react';
import { useEffect, useRef, useState, Children } from 'react';
import { useDebounce } from 'rooks';
import Arrow from '../../../icons/direction/arrow/arrow.svg';
import styles from './ScrollSnapSlider.module.scss';

/**
 * Restrict input to valid size units
 */
type cssSizeUnit =
  | `${number}vw`
  | `${number}vh`
  | `${number}rem`
  | `${number}em`
  | `${number}px`
  | `${number}%`;

interface ScrollSnapSliderProps {
  id?: string;
  /**
   * Array of child elements
   */
  children?: ReactNode;
  spacing?: cssSizeUnit | null;
  height?: cssSizeUnit | null;
  itemviewTransform?: string;
  reverse?: boolean;
  dots?: boolean;
  className?: string;
  hideDisabledNavButton?: boolean;
  clickToSwitch?: boolean;
}

/**
 * Scroll Snap Slider
 *
 * @param id: String | undefined - Optional ID string
 * @param children: ReactNode - Only an array of ReactNode children will be accepted
 * @param height: CSSVar | null | undefined - Valid CSS value, NULL to unset var or default
 * @param spacing: CSSVar | null | undefined - Valid CSS value, NULL to unset var or default
 * @param itemviewTransform: string | undefined - Valid CSS transform or undefined
 * @param reverse: boolean - Reverse direction of slider
 * @param className: string | undefined - Classname string
 * @param clickToSwitch: boolean - Clicking a slide will switch the slider index to its one
 * @parap dots: boolean
 * @returns ReactNode
 */
export const ScrollSnapSlider = ({
  id = 'defaults',
  height = '30rem',
  children,
  spacing = '1rem',
  reverse = false,
  dots = false,
  itemviewTransform,
  className,
  hideDisabledNavButton = false,
  clickToSwitch = false,
}: ScrollSnapSliderProps) => {
  /**
   * Ensure passed children are spread into an array of React elements
   */
  const elements = Children.toArray(children);

  /**
   * Scoll-snap container element
   */

  const snapContainer = useRef<HTMLDivElement>(null);

  /**
   * All snapable elements
   */
  const snapElements = useRef<HTMLDivElement[]>([]);

  /**
   * Slider scrollable state
   */
  const [isScrollableCarousel, setScrollable] = useState(false);

  /**
   * Slider index
   */
  const [sliderIndex, setSliderIndex] = useState(0);

  /**
   * Set Slider Index (Debounced to prrevent excessive write)
   */
  const setSliderIndexDebounced = useDebounce(setSliderIndex);
  /**
   * How far is the snapContainer currently scrolled
   */
  const scrolledX = () => Math.round(snapContainer.current?.scrollLeft ?? 0);

  /**
   * Width of scrollable area
   */
  const scrollAvailable = () => snapContainer.current?.scrollWidth ?? 0;
  /**
   * Width of snapContainer
   */
  const widthAvailable = () => snapContainer.current?.clientWidth ?? 0;
  /**
   * Offset Width of snapContainer
   */
  const offsetWidth = () => snapContainer.current?.offsetWidth ?? 0;

  /**
   * Remaining scrollable area
   */
  const scrollRemaining = () => scrollAvailable() - scrolledX();

  /**
   * Is there enough space remaining to animate a scroll
   */
  const [canAdvance, setCanAdvance] = useState(true);

  const setCanAdvanceDebounced = useDebounce(setCanAdvance);

  const handleScroll = () => {
    const scrollBuffer = 5;
    /**
     * Find if which element is currently at the left edge of the
     * of the snapContainer
     */
    const cursor = !reverse
      ? scrolledX() + scrollBuffer
      : scrolledX() + widthAvailable() - scrollBuffer;

    /**
     * Loop through the slides and get the index of the first element that
     * satisfies the following check:
     * - For each scroll snap element:
     *   - Get either the next element, or the current element if you are
     *     checking the final element in the array.
     *   - Check the next element's `offsetLeft`, or if going in reverse, check
     *     the current element's `offsetLeft`
     *   - Check the offset against the `cursor` (the amount the user has
     *     scrolled) and return the first element where the cursor is less than
     *     or equal to the offset, which means previous elements are not fully
     *     in view. (Checks greater than or equal to for the `reverse` mode)
     *   - If the slide is the last slide, we add on the scroll buffer amount
     *     that we add to the `cursor` to handle when the value are the same.
     */
    const newIndex = snapElements.current.findIndex((element, index, array) => {
      /**
       * Next index / last index
       */
      const nextIndexNumber = index + 1;

      /**
       * Check the generated `nextIndexNumber` is equal or larger to the array
       * length.
       * - If so, update the index to use to be the current index, so that we
       *   aren't referencing a non-existent element in our offset checks.
       */
      const isLastElement = nextIndexNumber >= array.length;
      const nextIndex = isLastElement ? index : nextIndexNumber;

      /**
       * Distance between the left hand edge of the next
       * element and the left hand edge of the snapContainer
       */
      const elementOffset = !reverse
        ? array[nextIndex]?.offsetLeft ?? 0
        : element?.offsetLeft;

      /**
       * Calculate additional offset buffer for the last element.
       * - Used to account for the `cursor` buffer when on the last slide.
       */
      const additionalOffsetBuffer = isLastElement ? scrollBuffer : 0;

      /**
       * Is the current distance scrolled between the edge
       * of the current element and the next element
       */
      return !reverse
        ? cursor <= elementOffset + additionalOffsetBuffer
        : cursor >= elementOffset - additionalOffsetBuffer;
    });

    determineSliderIndex(newIndex);

    setCanAdvanceDebounced(
      !reverse
        ? scrollRemaining() - widthAvailable() - 5 >= 0
        : widthAvailable() + scrolledX() * -1 + 5 <= scrollAvailable()
    );
  };

  /**
   * Sets the index of the element to which the slider has scrolled
   * which impacts Arrow buttons and Pip buttons configs
   *
   * @param newIndex - number
   */
  const determineSliderIndex = (newIndex: number) => {
    /**
     * If index is one before the last element and
     * the space left to scroll and snap container width are the same
     * set index to last element
     */
    if (
      newIndex <= elements.length - 2 &&
      widthAvailable() >= scrollRemaining()
    ) {
      setSliderIndexDebounced(newIndex + 1);
      /**
       * If index is one but scroll has begun set index to 1
       */
    } else if (newIndex === 0 && scrolledX() > 0) {
      setSliderIndexDebounced(1);
    } else {
      setSliderIndexDebounced(newIndex);
    }
  };

  const setSlide = (index: number) => {
    /**
     * Distance between current slide and target slide
     */
    const delta = !reverse
      ? scrolledX() - snapElements.current[index].offsetLeft
      : scrolledX() +
        widthAvailable() -
        snapElements.current[index].offsetLeft -
        snapElements.current[index].clientWidth;

    /**
     * Distance available to scroll
     */
    const remaining = !reverse
      ? scrolledX() + widthAvailable() - scrollAvailable()
      : scrolledX() - widthAvailable() + scrollAvailable();

    /**
     * Is Delta travel further than maximum?
     */
    const outsideBounds = !reverse ? delta < remaining : delta > remaining;

    /**
     * Scroll using native smooth mechanisms
     */
    if (snapContainer.current)
      snapContainer.current.scrollTo({
        left: scrolledX() - (!outsideBounds ? delta : remaining),
        top: 0,
        behavior: 'smooth',
      });
  };

  /**
   * Calculates slide count taking into account:
   * - Total scroll width
   * - Carousel view port
   * - Single slide width
   *
   * @returns Number of slides in the carousel
   */
  const dotsCount = (): number => {
    const childrenCount = elements.length;
    const sliderVisibleWidth = widthAvailable();
    const totalWidth = scrollAvailable();
    const singleChildWidth = totalWidth / childrenCount;

    return Math.ceil((totalWidth - sliderVisibleWidth) / singleChildWidth) + 1;
  };

  /**
   * Detect scrollable carousel after elements are set
   */
  useEffect(() => {
    setScrollable(scrollAvailable() > offsetWidth());
  });

  /**
   * If elements are not array return null
   */
  if (!elements.length) return null;

  return (
    <div
      className={clsx(styles.scrollSnapSlider, className)}
      dir={reverse ? 'rtl' : 'ltr'}
    >
      <div
        className={styles.scrollSnapSlider__crop}
        style={
          {
            '--slider-height': height !== null ? height : undefined,
            '--slider-spacing': spacing !== null ? spacing : undefined,
            '--itemview-transform': itemviewTransform,
            '--nav-arrows-display': isScrollableCarousel ? 'block' : 'none',
            '--nav-arrows-disabled-opacity': hideDisabledNavButton ? 0 : 0.5,
          } as CSSProperties
        }
      >
        <div
          className={styles.scrollSnapSlider__content}
          ref={snapContainer}
          onScroll={handleScroll}
        >
          <div className={styles.scrollSnapSlider__platter}>
            {elements.map((element, i) => {
              return (
                <div
                  className={clsx(
                    { [styles.scrollSnapSlider__listitem]: !reverse },
                    {
                      [styles['scrollSnapSlider__listitem--reverse']]: reverse,
                    }
                  )}
                  key={i}
                  ref={(element) =>
                    element ? (snapElements.current[i] = element) : null
                  }
                  dir="ltr"
                  data-slider-slide={id}
                  onClick={clickToSwitch ? () => setSlide(i) : undefined}
                  onKeyDown={clickToSwitch ? () => setSlide(i) : undefined}
                  role={clickToSwitch ? 'button' : ''}
                  tabIndex={clickToSwitch ? i : undefined}
                >
                  <div
                    className={styles.scrollSnapSlider__itemview}
                    data-slider-itemview={id}
                  >
                    {element}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <button
          className={clsx(
            styles.scrollSnapSlider__navButton,
            { [styles['scrollSnapSlider__navButton--prev']]: !reverse },
            { [styles['scrollSnapSlider__navButton--prevReverse']]: reverse }
          )}
          disabled={sliderIndex === 0}
          onClick={() => setSlide(sliderIndex - 1)}
          data-nav-available={sliderIndex !== 0 ? true : undefined}
          data-previous-button={id}
          type="button"
        >
          <span className="visually-hidden">Previous</span>
          <Arrow />
        </button>
        <button
          className={clsx(
            styles.scrollSnapSlider__navButton,
            { [styles['scrollSnapSlider__navButton--next']]: !reverse },
            { [styles['scrollSnapSlider__navButton--nextReverse']]: reverse }
          )}
          disabled={!canAdvance}
          onClick={() => setSlide(sliderIndex + 1)}
          data-nav-available={canAdvance ? true : undefined}
          data-next-button={id}
          type="button"
        >
          <span className="visually-hidden">Next</span>
          <Arrow />
        </button>
      </div>
      {dots && (
        <ul
          className={styles.scrollSnapSlider__slidePips}
          data-slider-pips={id}
        >
          {Array.from({ length: dotsCount() }).map((_, i) => {
            return (
              <li className={styles.scrollSnapSlider__slidePip} key={i}>
                <button
                  className={styles.scrollSnapSlider__slidePipButton}
                  onClick={() => setSlide(i)}
                  type="button"
                >
                  <span className="visually-hidden">Skip to slide {i + 1}</span>
                  <span
                    className={styles.scrollSnapSlider__dot}
                    data-current={sliderIndex === i ? true : undefined}
                  />
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
